import React from 'react'
import { Container } from 'semantic-ui-react'
import Layout from '../components/Layouts/cakeDefault'
import { HOTLINE_ADVICE } from '../utils/constants.js'

export default function TermPage() {
  return (
    <Layout endPoint={'dieu-khoan'} title={'Savor Cake | Điều khoản'}>
      <Container text style={{ marginTop: 20 }}>
        <h2>Chính sách &amp; Quy định chung</h2>
        <p>
          <strong>1. Giới thiệu</strong>
        </p>
        <p>Chào mừng bạn đã đến với Savor.vn.</p>
        <p>
          Chúng tôi là hệ thống cửa hàng có địa chỉ trụ sở và địa chỉ giao dịch
          tại số 111, ngõ 231, phố Chùa Bộc, quận Đống Đa, thành phố Hà Nội.
          Website đã hoàn tất thủ tục thông báo Bộ Công Thương Việt Nam.
        </p>
        <p>
          Khi bạn truy cập vào Savor.vn có nghĩa là bạn đã đồng ý với các điểm
          đã nêu trong bài viết này. Savor.vn có quyền thay đổi, chỉnh sửa, thêm
          hoặc lược bỏ bất kỳ phần nào trong trang Chính sách &amp; Quy định
          chung này, vào bất cứ lúc nào. Các thay đổi có hiệu lực ngay khi được
          đăng trên trang web mà không cần thông báo trước. Và khi bạn tiếp tục
          sử dụng trang web, sau khi các thay đổi về Điều khoản này được đăng
          tải, có nghĩa là bạn chấp nhận với những thay đổi đó.
        </p>
        <p>
          <strong>2. Hướng dẫn sử dụng website</strong>
        </p>
        <p>
          Khi vào web của chúng tôi, bạn phải đảm bảo đủ 18 tuổi, hoặc truy cập
          dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp. Bạn đảm bảo có
          đầy đủ hành vi dân sự để thực hiện các giao dịch mua bán hàng hóa theo
          quy định hiện hành của pháp luật Việt Nam.
        </p>
        <p>
          Chúng tôi sẽ cấp một tài khoản (Account) sử dụng để bạn có thể mua sắm
          trên website Savor.vn trong khuôn khổ Điều khoản và Điều kiện sử dụng
          đã đề ra.
        </p>
        <p>
          Bạn sẽ phải đăng ký tài khoản với thông tin xác thực về bản thân và
          phải cập nhật nếu có bất kỳ thay đổi nào. Mỗi người truy cập phải có
          trách nhiệm với mật khẩu, tài khoản và hoạt động của mình trên web.
          Hơn nữa, bạn phải thông báo cho chúng tôi biết khi tài khoản bị truy
          cập trái phép. Chúng tôi không chịu bất kỳ trách nhiệm nào, dù trực
          tiếp hay gián tiếp, đối với những thiệt hại hoặc mất mát gây ra do quý
          khách không tuân thủ quy định.
        </p>
        <p>
          Nghiêm cấm sử dụng bất kỳ phần nào của trang web này với mục đích
          thương mại hoặc nhân danh bất kỳ đối tác thứ ba nào nếu không được
          chúng tôi cho phép bằng văn bản. Nếu vi phạm bất cứ điều nào trong
          đây, chúng tôi sẽ hủy tài khoản của khách mà không cần báo trước.
        </p>
        <p>
          Trong suốt quá trình đăng ký, quý khách đồng ý nhận email quảng cáo từ
          website. Nếu không muốn tiếp tục nhận mail, quý khách có thể từ chối
          bằng cách nhấp vào đường link ở dưới cùng trong mọi email quảng cáo.
        </p>
        <p>
          <strong>3. Ý kiến của bạn</strong>
        </p>
        <p>
          Tất cả nội dung trang web và ý kiến phê bình của quý khách đều là tài
          sản của chúng tôi. Nếu chúng tôi phát hiện bất kỳ thông tin giả mạo
          nào, chúng tôi sẽ khóa tài khoản của quý khách ngay lập tức hoặc áp
          dụng các biện pháp khác theo quy định của pháp luật Việt Nam.
        </p>
        <p>
          <strong>4. Chấp nhận đơn hàng và giá cả</strong>
        </p>
        <p>
          Chúng tôi có quyền từ chối hoặc hủy đơn hàng của quý khách vì bất kỳ
          lý do gì liên quan đến lỗi kỹ thuật, hệ thống một cách khách quan vào
          bất kỳ lúc nào. Chúng tôi có thể hỏi thêm về số điện thoại và địa chỉ
          trước khi nhận đơn hàng.
        </p>
        <p>
          Chúng tôi cam kết sẽ cung cấp thông tin giá cả chính xác nhất cho
          người tiêu dùng. Tuy nhiên, đôi lúc vẫn có sai sót xảy ra, ví dụ như
          trường hợp giá sản phẩm không hiển thị chính xác trên trang web hoặc
          sai giá, tùy theo từng trường hợp chúng tôi sẽ liên hệ hướng dẫn hoặc
          thông báo hủy đơn hàng đó cho quý khách. Chúng tôi cũng có quyền từ
          chối hoặc hủy bỏ bất kỳ đơn hàng nào dù đơn hàng đó đã hay chưa được
          xác nhận hoặc đã thanh toán.
        </p>
        <p>
          <strong>5. Thay đổi hoặc hủy bỏ giao dịch tại Savor.vn</strong>
        </p>
        <p>
          Trong mọi trường hợp, bạn đều có quyền chấm dứt giao dịch nếu đã thực
          hiện các biện pháp sau đây:
        </p>
        <ul>
          <li>
            Thông báo cho Savor.vn về việc hủy giao dịch qua đường dây nóng
            (hotline) {HOTLINE_ADVICE}
          </li>
          <li>
            Trả lại hàng hoá đã nhận nhưng chưa sử dụng hoặc hưởng bất kỳ lợi
            ích nào từ hàng hóa đó (theo quy định của{' '}
            <strong>chính sách đổi trả hàng</strong>)
          </li>
        </ul>
        <p>
          <strong>
            6. Giải quyết hậu quả do lỗi nhập sai thông tin tại Savor.vn
          </strong>
        </p>
        <p>
          bạn có trách nhiệm cung cấp thông tin đầy đủ và chính xác khi tham gia
          giao dịch tại Savor.vn. Trong trường hợp bạn nhập sai thông tin và gửi
          vào trang TMĐT Savor.vn, Savor.vn có quyền từ chối thực hiện giao
          dịch. Ngoài ra, trong mọi trường hợp, bạn đều có quyền đơn phương chấm
          dứt giao dịch nếu đã thực hiện các biện pháp sau đây:
        </p>
        <ul>
          <li>Thông báo cho Savor.vn qua đường dây nóng {HOTLINE_ADVICE}</li>
          <li>
            Trả lại hàng hoá đã nhận nhưng chưa sử dụng hoặc hưởng bất kỳ lợi
            ích nào từ hàng hóa đó theo đúng quy định trong phần{' '}
            <strong>Quy định đổi trả hàng hoá</strong>.
          </li>
        </ul>
        <p>
          Trong trường hợp sai thông tin phát sinh từ phía Savor.vn mà Savor.vn
          có thể chứng minh đó là lỗi của hệ thống hoặc từ bên thứ ba (sai giá
          sản phẩm, sai xuất xứ, …), Savor.vn sẽ đền bù cho bạn một mã giảm giá
          cho các lần mua sắm tiếp theo với mệnh giá tùy từng trường hợp cụ thể
          và có quyền không thực hiện giao dịch bị lỗi.
        </p>
        <p>
          <strong>7. Thương hiệu và bản quyền</strong>
        </p>
        <p>
          Mọi quyền sở hữu trí tuệ (đã đăng ký hoặc chưa đăng ký), nội dung
          thông tin và tất cả các thiết kế, văn bản, đồ họa, phần mềm, hình ảnh,
          video, âm nhạc, âm thanh, biên dịch phần mềm, mã nguồn và phần mềm cơ
          bản đều là tài sản của Savor.vn. Toàn bộ nội dung của trang web được
          bảo vệ bởi luật bản quyền của Việt Nam và các công ước quốc tế.
        </p>
        <p>
          <strong>8. Quyền pháp lý</strong>
        </p>
        <p>
          Các điều kiện, điều khoản và nội dung của trang web này được điều
          chỉnh bởi luật pháp Việt Nam và Tòa án có thẩm quyền tại Việt Nam sẽ
          giải quyết bất kỳ tranh chấp nào phát sinh từ việc sử dụng trái phép
          trang web này.
        </p>
        <p>
          <strong>9. Quy định về bảo mật</strong>
        </p>
        <p>
          Trang web của chúng tôi coi trọng việc bảo mật thông tin và sử dụng
          các biện pháp tốt nhất bảo vệ thông tin và việc thanh toán của quý
          khách. Thông tin của quý khách trong quá trình thanh toán sẽ được mã
          hóa để đảm bảo an toàn. Sau khi quý khách hoàn thành quá trình đặt
          hàng, quý khách sẽ thoát khỏi chế độ an toàn.
        </p>
        <p>
          Quý khách không được sử dụng bất kỳ chương trình, công cụ hay hình
          thức nào khác để can thiệp vào hệ thống hay làm thay đổi cấu trúc dữ
          liệu. Trang web cũng nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho
          bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu
          của hệ thống. Cá nhân hay tổ chức vi phạm sẽ bị tước bỏ mọi quyền lợi
          cũng như sẽ bị truy tố trước pháp luật nếu cần thiết.
        </p>
        <p>
          Mọi thông tin giao dịch sẽ được bảo mật ngoại trừ trong trường hợp cơ
          quan pháp luật yêu cầu.
        </p>
        <p>
          <strong>10. Thanh toán an toàn và tiện lợi tại Savor.vn</strong>
        </p>
        <p>
          Người mua có thể tham khảo các phương thức thanh toán sau đây và lựa
          chọn áp dụng phương thức phù hợp:
        </p>
        <p>
          <strong>
            <u>Cách 1</u>
          </strong>
          : Thanh toán trực tiếp (người mua nhận hàng tại địa chỉ người bán):
        </p>
        <p>
          Bước 1: Người mua tìm hiểu thông tin về sản phẩm, dịch vụ được đăng
          tin;
        </p>
        <p>Bước 2: Người mua đến địa chỉ bán hàng</p>
        <p>Bước 3: Người mua thanh toán và nhận hàng;</p>
        <p>
          <strong>
            <u>Cách 2</u>
          </strong>
          <strong>:</strong> Thanh toán sau (COD – giao hàng và thu tiền tận
          nơi):
        </p>
        <p>
          Bước 1: Người mua tìm hiểu thông tin về sản phẩm, dịch vụ được đăng
          tin;
        </p>
        <p>
          Bước 2: Người mua xác thực đơn hàng (điện thoại, tin nhắn, email);
        </p>
        <p>Bước 3: Người bán xác nhận thông tin Người mua;</p>
        <p>Bước 4: Người bán chuyển hàng;</p>
        <p>Bước 5: Người mua nhận hàng và thanh toán.</p>
        <p>
          <strong>
            <u>Cách 3</u>
          </strong>
          <strong>:</strong> Thanh toán online qua thẻ tín dụng, chuyển khoản
        </p>
        <p>
          Bước 1: Người mua tìm hiểu thông tin về sản phẩm, dịch vụ được đăng
          tin;
        </p>
        <p>
          Bước 2: Người mua xác thực đơn hàng (điện thoại, tin nhắn, email);
        </p>
        <p>Bước 3: Người bán xác nhận thông tin Người mua;</p>
        <p>Bước 4: Ngưởi mua thanh toán;</p>
        <p>Bước 5: Người bán chuyển hàng;</p>
        <p>Bước 6: Người mua nhận hàng.</p>
        <p>
          Đối với người mua hàng từ Savor.vn thì phải tuẩn thu theo chính sách
          thanh toán của công ty.
        </p>
        <p>
          <strong>11. Đảm bảo an toàn giao dịch tại Savor.vn</strong>
        </p>
        <p>
          Chúng tôi sử dụng các dịch vụ để bảo vệ thông tin về nội dung mà người
          bán đăng sản phẩm trên Savor.vn. Để đảm bảo các giao dịch được tiến
          hành thành công, hạn chế tối đa rủi ro có thể phát sinh.
        </p>
        <p>
          <strong>12. Giải quyết tranh chấp</strong>
        </p>
        <p>
          Bất kỳ tranh cãi, khiếu nại hoặc tranh chấp phát sinh từ hoặc liên
          quan đến giao dịch tại Savor.vn hoặc các Quy định và Điều kiện này đều
          sẽ được giải quyết bằng hình thức thương lượng, hòa giải, trọng tài
          và/hoặc Tòa án theo Luật bảo vệ Người tiêu dùng Chương 4 về Giải quyết
          tranh chấp giữa người tiêu dùng và tổ chức, cá nhân kinh doanh hàng
          hóa, dịch vụ.
        </p>
        <p>
          <strong>13. Luật pháp và thẩm quyền tại Lãnh thổ Việt Nam</strong>
        </p>
        <p>
          Tất cả các Điều Khoản và Điều Kiện này và Hợp Đồng (và tất cả nghĩa vụ
          phát sinh ngoài hợp đồng hoặc có liên quan) sẽ bị chi phối và được
          hiểu theo luật pháp của Việt Nam. Nếu có tranh chấp phát sinh bởi các
          Quy định Sử dụng này, bạn có quyền gửi khiếu nại/khiếu kiện lên Tòa án
          có thẩm quyền tại Việt Nam để giải quyết.
        </p>
      </Container>
    </Layout>
  )
}
